import { persistenceType } from "lib/jetton-minter";
import BurnJettonsAction from "./actions/BurnJettonsAction";
import MintJettonsAction from "./actions/MintJettonsAction";
import RevokeOwnershipAction from "./actions/RevokeOwnershipAction";
import { JettonDetailMessage } from "./types";
import i18n from "i18n";
export { BigNumber } from "bignumber.js";

const commonGithubUrl =
  "https://github.com/ton-blockchain/minter-contract#protect-yourself-and-your-users";

const offChainGithubUrl =
  "https://github.com/ton-blockchain/minter-contract#jetton-metadata-field-best-practices";

export const getFaultyMetadataWarning = (isAdminRevokedOwnership?: boolean) => {
  if (isAdminRevokedOwnership) {
    return "This token was created with a previous faulty version of the tool. The token is permanently unusable, please contact the admin to redeploy a new token";
    // return "This token was created with a previous faulty version of the tool. The token is permanently unusable, please contact the admin to redeploy a new token";
  }
  // return "This token was created with a previous faulty version of the tool. The token is now unusable but can be fixed, please contact the admin to fix it using this page";
  return "This token was created with a previous faulty version of the tool. The token is now unusable but can be fixed, please contact the admin to fix it using this page";
};

export const adminActions = [RevokeOwnershipAction];

export const totalSupplyActions = [MintJettonsAction];

export const balanceActions = [BurnJettonsAction];

export const getAdminMessage = (
  adminAddress?: string,
  symbol?: string,
  isRevokedOwnership?: boolean,
  isAdmin?: boolean,
  jettonAddress?: string,
): JettonDetailMessage | undefined => {
  if (!jettonAddress) {
    return undefined;
  }
  if (isRevokedOwnership) {
    return {
      type: "success",
      text: i18n.t("权限已经丢弃"),
    };
  }
  if (isAdmin) {
    return {
      type: "warning",
      // text: `You should revoke this token's ownership. Your ${symbol} tokens will
          // remain safely in your wallet. [Read more](${commonGithubUrl})`,
      text: i18n.t("未丢弃权限")
    };
  }

  return {
    type: "warning",
    // text: `This token is not 100% safe because admin has not revoked ownership. [Read more](${commonGithubUrl})`,
    text: i18n.t("未丢弃权限"),
  };
};

export const getMetadataWarning = (
  persistenceType?: persistenceType,
  adminRevokedOwnership?: boolean,
): JettonDetailMessage | undefined => {
  if (persistenceType === "onchain" && !adminRevokedOwnership) {
    return {
      type: "warning",
      text: i18n.t("可更改"),
    };
  }
  switch (persistenceType) {
    case "offchain_ipfs":
      return {
        type: "warning",
        text: i18n.t('该jetton的Metadata数据（名称、小数和符号）存储在IPFS上而不是链上。它不会改变，但要小心，它可能会消失并变得不固定'),
      };
    case "offchain_private_domain":
      return {
        type: "warning",
        text: i18n.t("由于MetaData数据存储在私有网站上，因此管理员可以在没有警告的情况下进行更改"),
      };

    default:
      return;
  }
};

export const getTotalSupplyWarning = (
  persistenceType?: persistenceType,
  adminRevokedOwnership?: boolean,
): JettonDetailMessage | undefined => {
  if (persistenceType === "onchain" && !adminRevokedOwnership) {
    return {
      type: "warning",
      text: i18n.t("管理员可增发"),
    };
  }
};
