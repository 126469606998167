import { Box, Drawer, IconButton, MenuItem, Select, styled } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AppLogo } from "components/appLogo";
import { CloseMenuButton, DrawerContent, AppMenu, HeaderTypography } from "./styled";
import { TonConnectButton } from "@tonconnect/ui-react";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { ROUTES } from "consts";
import { useTranslation } from "react-i18next";
import cn from "assets/flag/cn.svg";
import ru from "assets/flag/ru.svg";
import usa from "assets/flag/usa.svg";
interface MenuProps {
  closeMenu?: () => void;
  showMenu?: boolean;
}

const MobileMenu: React.FC<MenuProps> = ({ closeMenu, showMenu }) => {
  return (
    <Drawer anchor="left" open={showMenu} onClose={closeMenu}>
      <CloseMenuButton onClick={closeMenu}>
        <CloseRoundedIcon style={{ width: 30, height: 30 }} />
      </CloseMenuButton>
      <DrawerContent>
        <AppLogo />
        <HeaderMenu showMenu={showMenu} closeMenu={closeMenu} />
      </DrawerContent>
    </Drawer>
  );
};

const HeaderMenu: React.FC<MenuProps> = (props) => {
  const navigate = useNavigatePreserveQuery();
  const { i18n } = useTranslation();
  // const lang = window.localStorage.getItem("lang") || "zh";
  const handleChange = (event: any) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
    window.location.reload();
  };
  return (
    <AppMenu>
      <IconButton
        sx={{ padding: 0, ml: 1.5, display: "none" }}
        onClick={() => navigate(ROUTES.multisend)}>
        <HeaderTypography variant="h5">批量工具</HeaderTypography>
      </IconButton>
      <LangSelect
        value={localStorage.getItem("language") || "zh"}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}>
        <LangMenuItem value="zh">
          <div>
            <img src={cn} width={16} alt="Chinese" /> <span>zh-CN</span>
          </div>
        </LangMenuItem>
        <LangMenuItem value="en">
          <div>
            <img src={usa} width={16} alt="USA" />
            <span>en-US</span>
          </div>
        </LangMenuItem>
        <LangMenuItem value="ru">
          <div>
            <img src={ru} width={16} alt="Russian" /> <span>ru-RU</span>
          </div>
        </LangMenuItem>
      </LangSelect>

      <div onClick={props.closeMenu}>
        <StyledTonConnectButton />
      </div>
      {/* <IconButton
        sx={{ padding: 0, ml: 1.5 }}
        href="https://t.me/pandatool"
        target="_blank">
        <StyledGithubIcon width={20} height={20} src={telegramIcon} />
        <HeaderTypography variant="h5">联系我们</HeaderTypography>
      </IconButton> */}
    </AppMenu>
  );
};

// 自定义 Select 组件样式
const LangSelect = styled(Select)(({ theme }) => ({
  height: 40,
  borderRadius: 999,
  borderColor: theme.palette.primary.main,
  // border: "0.5px solid rgba(114, 138, 150, 0.16)",
  fontWeight: 700,
  background: "#F7F9FB",
  svg: {
    display: "none",
  },
  div: {
    display: "flex",
    paddingRight: "10px !important",
    img:{
      marginLeft: "0.25rem",
    },
    span: {
      marginLeft: "0.25rem",
    },
  },
}));

// 自定义 MenuItem 组件样式
const LangMenuItem = styled(MenuItem)(({ theme }) => ({
  div: {
    display: "flex",

    img: {
      marginTop: 2,
    },
    span: {
      marginLeft: "0.25rem",
    },
  },
}));

const StyledTonConnectButton = styled(TonConnectButton)(({ theme }) => ({
  button: {
    background: theme.palette.primary.main,
    "*": { color: "white" },
    svg: {
      "*": {
        stroke: "white",
      },
    },
  },
}));

export { HeaderMenu, MobileMenu };
