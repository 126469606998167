import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation.json";
import zh from "./locales/zh/translation.json";
import ru from "./locales/ru/translation.json";
const savedLanguage = localStorage.getItem('language') || 'zh';
const resources = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
  ru: {
    translation: ru,
  },
};
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: savedLanguage,
    debug: false,
    resources,
  });

export default i18n;
