import React from "react";
import { Popup } from "components/Popup";
import { Box, Typography } from "@mui/material";
import { CenteringWrapper } from "components/header/headerSearchBar/styled";
import { AppButton } from "components/appButton";
import openLink from "assets/icons/link-open.svg";
import { PopupLink, PopupTitle } from "components/editLogoPopup/styled";
import { useTranslation } from "react-i18next";

interface LogoAlertPopupProps {
  showPopup: boolean;
  close: () => void;
  onValidate: any;
  isUpdateText: boolean;
}

export const LogoAlertPopup = ({
  showPopup,
  close,
  onValidate,
  isUpdateText,
}: LogoAlertPopupProps) => {
  const {t} = useTranslation()
  return (
    <Popup open={showPopup} onClose={close} maxWidth={592}>
      <PopupTitle>{t("logo链接已经失效")}</PopupTitle>
      <Typography sx={{ alignSelf: "baseline" }} mb={0.5}>
        {t("您的代币")} <span style={{ fontWeight: 700 }}>{t("logo链接已失效")}.</span>
      </Typography>
      <Typography sx={{ alignSelf: "baseline", lineHeight: 2 }}>
         {t("您如果还有权限可以重新上传")} <br />
        
      </Typography>
      <Box sx={{ alignSelf: "baseline" }} mx={2} mt={1}>
        <PopupLink
          href="https://docs.google.com/document/d/1-687dbe4pds37YnH0fPQB8PWlV8bvZ5ittBGPV7k9Kw/edit?usp=sharing"
          target="_blank">
          {t("建议讲您的logo放在gitHub上，并在metadata中添加链接。")}
          <img alt="Open icon" src={openLink} width={11} height={11} style={{ marginLeft: 4 }} />
        </PopupLink>
      </Box>
      <CenteringWrapper mb={2}>
        <CenteringWrapper mr={2}>
          <AppButton
            height={44}
            width={98}
            fontWeight={700}
            type="button"
            transparent
            onClick={close}>
            {t("取消")}
          </AppButton>
        </CenteringWrapper>
        <AppButton
          height={44}
          width={isUpdateText ? 140 : 98}
          fontWeight={700}
          type="button"
          onClick={() => {
            onValidate();
            close();
          }}
          background="#0088CC">
          {isUpdateText ? t("更新 Metadata") : t("发布")}
        </AppButton>
      </CenteringWrapper>
    </Popup>
  );
};
