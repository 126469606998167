import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { AppButton } from "components/appButton";
import { Popup } from "components/Popup";
import openLink from "assets/icons/link-open.svg";
import { useAutosizeTextArea } from "hooks/useAutoResizeTextArea";
import useNotification from "hooks/useNotification";
import {
  LogoTextArea,
  LogoTextAreaWrapper,
  PopupContent,
  PopupDescription,
  PopupLink,
  PopupTitle,
} from "components/editLogoPopup/styled";
import "./style.css";
import { useJettonLogo } from "hooks/useJettonLogo";
import { useTranslation } from "react-i18next";

interface EditLogoPopupProps {
  showPopup: boolean;
  tokenImage: any;
  close: () => void;
  showExample?: boolean;
}

export const EditLogoPopup = ({
  showPopup,
  tokenImage,
  showExample,
  close,
}: EditLogoPopupProps) => {
  const { t } = useTranslation();
  const { jettonLogo, setLogoUrl } = useJettonLogo();
  const [tempUrl, setTempUrl] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  const [preview, setPreview] = useState<any>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef.current, tempUrl);
  const { showNotification } = useNotification();
  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;

    setTempUrl(val);
  };
  const handleFileChange = async (evt: any) => {
    setPreview(null)
    setTempUrl("");
    const file = evt.target?.files[0];
    if (file && file.size > 100000) {
      showNotification(t("图片大小不能超过100KB"), "error");
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
    const formData = new FormData();
    formData.append("img_name", file.name);
    formData.append("file", file);
    // console.log("File submitted:", selectedFile);
    try {
      showNotification(t("上传中..."), "info");
      const res = await fetch("https://solana.pandatool.org/api/upload_img?secret=GET_METAURI", {
        method: "POST",
        body: formData,
      });
      if (!res.ok) {
        showNotification(t("URI上传失败"), "error");
        throw new Error("URI上传失败");
      }
      const data = await res.json();
      console.log(data);
      setTempUrl(data.imgURI);
      showNotification(t("上传成功"), "success");
    } catch (e) {
      showNotification(t("URI上传失败!请重试"), "error");
      console.log(e);
    }
  };
  useEffect(() => {
    setTempUrl(jettonLogo.logoUrl);
  }, [showPopup]);

  return (
    <Popup open={showPopup} onClose={close} maxWidth={644}>
      <PopupTitle>{t("logo编辑")}</PopupTitle>
      <Box sx={{ width: "100%" }}>
        <PopupContent>
          <LogoTextAreaWrapper>
            <LogoTextArea
              spellCheck={false}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
              value={tempUrl}
              onChange={handleChange}
              ref={textAreaRef}
              rows={1}
            />
          </LogoTextAreaWrapper>
        </PopupContent>
        <PopupDescription>
          {tokenImage.description}{" "}
          {showExample && (
            <span
              onClick={() => setTempUrl("https://gateway.irys.xyz/_uFltpsHDJha0NRDBIfn9AwFmJAAMjsWAJswBAuYGXI")}
              style={{ fontWeight: 700, cursor: "pointer" }}>
              {t("例子")}
            </span>
          )}
        </PopupDescription>
        <Box mt={2} mb={1}>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="file-input-container file-input"
          />
        </Box>
        {preview && (
          <Box mt={2} mb={1}>
            <img src={preview} alt="Preview" className="preview-img" />
          </Box>
        )}
        <Box mx={2} mt={!tempUrl ? 0 : 1} sx={{ display: "inline-flex" }}>
          {/* <PopupLink href="" target="_blank"> */}
          <span style={{ color: "#6b7280", fontSize: 14, marginRight: 8 }}>
            {t("点击上方按钮上传你的图片，图片大小不能超过100KB, 格式支持PNG, JPG, JPEG")}
          </span>
          {/* <img alt="Open icon" src={openLink} width={11} height={11} style={{ marginLeft: 4 }} /> */}
          {/* </PopupLink> */}
        </Box>
        <Box>
          <AppButton
            disabled={!tempUrl}
            height={44}
            width={118}
            fontWeight={700}
            type="button"
            onClick={() => {
              setLogoUrl(tempUrl);
              close();
            }}
            background="#0088CC">
            {t("保存")}
          </AppButton>
        </Box>
      </Box>
    </Popup>
  );
};
