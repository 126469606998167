import { checkImageURL, checkDecimals } from "helpers";
import i18n from "i18n";

const onchainFormSpec = [
  {
    name: "name",
    label: i18n.t("代币名称"),
    description: i18n.t("输入你的代币名称，不要超过3个单词"),
    type: "text",
    default: "Panda Coin",
    required: true,
    errorMessage: i18n.t("填入代币名称"),
  },
  {
    name: "symbol",
    label: i18n.t("代币符号"),
    description: i18n.t("输入代币符号"),
    type: "text",
    default: "PANDA",
    required: true,
    errorMessage: i18n.t("填入代币符号"),
  },
  {
    name: "decimals",
    label: i18n.t("精度"),
    description: i18n.t("精度建议不要超过9."),
    type: "number",
    validate: checkDecimals,
    default: 9,
    showDefault: true,
    required: true,
    errorMessage: i18n.t("错误的精度"), // https://github.com/ton-blockchain/TEPs/blob/master/text/0064-token-data-standard.md#jetton-metadata-attributes
  },
  {
    name: "mintAmount",
    label: i18n.t("发行总量"),
    description: i18n.t("成功铸造后会发送到您的发币钱包"),
    type: "number",
    default: 21000000,
    required: true,
    errorMessage: i18n.t("输入发行总量"),
  },
  {
    name: "description",
    label: i18n.t("代币简介"),
    description: i18n.t("简述您的代币"),
    type: "string",
    default: i18n.t("熊猫币是由Panda Tool发行的数字资产"),
  },

  {
    name: "tokenImage",
    label: i18n.t("代币LOGO"),
    description: i18n.t("填入你的代币LOGO图片地址，或者点击下方上传按钮上传图片"),
    type: "string",
    required: false,
    validate: checkImageURL,
    default: "https://gateway.irys.xyz/_uFltpsHDJha0NRDBIfn9AwFmJAAMjsWAJswBAuYGXI",
  },
];
const offchainFormSpec = [
  {
    name: "offchainUri",
    label: "Offchain URI",
    description: i18n.t("请输入图片url"),
    type: "string",
    default: "",
    required: true,
    errorMessage: i18n.t("请输入图片url"),
  },
  {
    name: "mintAmount",
    label: "Amount to Mint",
    description: i18n.t("要铸造并发送到您钱包地址的初始代币数量"),
    type: "number",
    default: 21000000,
    required: true,
    errorMessage: i18n.t("请输入mint数量"),
    disabled: undefined,
  },
];

export { onchainFormSpec, offchainFormSpec };
