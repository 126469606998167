import { useEffect, useState } from "react";
import { useTonAddress, useTonConnectUI, SendTransactionRequest } from "@tonconnect/ui-react";
import { toNano, Address } from "ton";
import { transfer } from "../../lib/jetton-minter";
import { toDecimalsBN } from "utils";
import { jettonDeployController } from "lib/deploy-controller";
import { zeroAddress, waitForSeqno } from "lib/utils";
import { getClient } from "lib/get-ton-client";
import useNotification from "hooks/useNotification";
import { Screen, ScreenContent } from "components/Screen";
import { Box, Fade, Link, Typography, styled } from "@mui/material";
import { FormWrapper, ScreenHeading, StyledDescription, SubHeadingWrapper } from "./style";
import { StyledActionBtn } from "components/form/styled";
import { CenteringWrapper } from "components/footer/styled";
import { AppButton } from "components/appButton";
import { onConnect } from "utils";
import "./style.css";
function Mutisend() {
  const address = useTonAddress();
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const [tokenAddress, setTokenAddress] = useState("");
  const [values, setValues] = useState("");
  const [isTon, setIsTon] = useState(true);
  const { showNotification } = useNotification();
  const tokenAddressChange = (e: any) => {
    setTokenAddress(e.target.value);
  };
  const valuesChange = (e: any) => {
    setValues(e.target.value);
  };

  const sendToken = async () => {
    try {
      const tokenArr: any[] = [];
      const amuntArr: any[] = [];
      const _arr = values.split(/[(\r\n)\r\n]+/);
      _arr.forEach((item, index) => {
        const arr = item.split(/[,，]+/);
        tokenArr.push(arr[0].trim());
        amuntArr.push(arr[1].trim());
      });

      console.log(tokenArr, amuntArr);

      const fromAddress = address;
      const tc = await getClient();

      const waiter = await waitForSeqno(
        tc.openWalletFromAddress({
          source: Address.parse(fromAddress),
        }),
      );
      const messages: any[] = [];

      if (isTon) {
        tokenArr.forEach((item, index) => {
          const obj = {
            address: item, // destination address
            amount: Number(amuntArr[index]) * 1000000000,
          };
          messages.push(obj);
        });
      } else {
        const parsedJettonMaster = Address.parse(tokenAddress);
        const result = await jettonDeployController.getJettonDetails(
          parsedJettonMaster,
          address ? Address.parse(address) : zeroAddress(),
        );
        const decimals = result.minter.metadata.decimals || "9";
        const ownerJettonWallet = result.jettonWallet?.jWalletAddress.toFriendly();

        tokenArr.forEach((item, index) => {
          const _amount = toDecimalsBN(amuntArr[index]!, decimals!);
          const obj = {
            address: ownerJettonWallet as string,
            amount: toNano(0.05).toString(),
            stateInit: undefined,
            payload: transfer(Address.parse(item), Address.parse(fromAddress), _amount)
              .toBoc()
              .toString("base64"),
          };
          messages.push(obj);
        });
      }

      messages.push({
        address: Address.parse("UQCLw8btpwKr4-7TPPAcKAqsDRPX7cO1y8WsKtsX3dljBMGX").toString(), // destination address
        amount: 0.1 * 1000000000,
      });
      const tx: SendTransactionRequest = {
        validUntil: Date.now() + 5 * 60 * 1000,
        messages: messages,
      };
      await tonConnectUI.sendTransaction(tx);
      await waiter();
    } catch (error: any) {
      showNotification(error.message, "error");
      // message.error(error.toString())
    }
  };

  return (
    <Screen>
      <ScreenContent removeBackground>
        <Fade in>
          <Box>
            <Box mb={3} mt={3.75}>
              <ScreenHeading variant="h5">批量转账</ScreenHeading>
            </Box>
            <FormWrapper>
              <SubHeadingWrapper>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    padding: 5,
                  }}>
                  <button
                    className={`j-button ${isTon ? "j-active" : ""}`}
                    onClick={() => {
                      setIsTon(true);
                    }}>
                    TON发送
                  </button>
                  <button
                    className={`j-button ${!isTon ? "j-active" : ""}`}
                    onClick={() => {
                      setIsTon(false);
                    }}>
                    Jetton代币发送
                  </button>
                </Box>
                {!isTon && (
                  <Box className="input">
                    <p className="j-node">输入代币地址</p>
                    <Box className="j-box">
                      <input
                        autoComplete="off"
                        placeholder="输入代币地址"
                        className="j-input"
                        type="text"
                        value={tokenAddress}
                        onChange={tokenAddressChange}
                      />
                    </Box>
                  </Box>
                )}
                <div style={{ marginTop: "40px" }}>
                  <p className="j-node">
                    收款地址和数量(一行一个地址和数量之间用逗号分隔){" "}
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        setValues(
                          "EQDtFpEwcFAEcRe5mLVh2N6C0x-_hJEM7W61_JLnSF74p4q2,100\nEQBeIy-SeFe2AteGRa90s33cVuQuRM6KUVDgT5UWHYhmsQDg,10",
                        );
                      }}>
                      点击查看例子
                    </span>
                  </p>
                  <textarea
                    autoComplete="off"
                    // onKeyDown={handleKeyDown}
                    className="j-textarea"
                    value={values}
                    onChange={valuesChange}
                  />
                </div>

                <Box className="btn">
                  <StyledActionBtn>
                    {!address ? (
                      <AppButton
                        height={44}
                        width={150}
                        fontWeight={700}
                        type="button"
                        onClick={onConnect}
                        background="#0088CC">
                        链接钱包
                      </AppButton>
                    ) : (
                      <AppButton
                        height={44}
                        width={150}
                        fontWeight={700}
                        type="button"
                        onClick={sendToken}
                        background="#0088CC">
                        发送
                      </AppButton>
                    )}
                  </StyledActionBtn>
                  {/* <button onClick={sendToken}>发送</button> */}
                </Box>
              </SubHeadingWrapper>
              <Box sx={{ flex: 4 }}>
                <Description />
              </Box>
            </FormWrapper>
          </Box>
        </Fade>
      </ScreenContent>
    </Screen>
  );
}

export default Mutisend;

const Spacer = () => {
  return <aside style={{ height: 25 }}></aside>;
};

function Description() {
  return (
    <StyledDescription sx={{ padding: 3 }}>
      <Typography
        variant="h5"
        mb={3}
        sx={{
          color: "#161C28",
          fontSize: 20,
          fontWeight: 800,
        }}>
        操作说明 |{" "}
        <Link target="_blank" href="https://help.pandatool.org/ton/stardand">
          查看详细教程
        </Link>
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          color: "#728A96",
          "& a": {
            textDecoration: "none",
            fontWeight: 500,
          },
        }}>
        <Link target="_blank" href="https://pandatool.org">
          PandaTool
        </Link>
        提供的这个工具允许您批量转账TON和Jeton代币。
        <Spacer />
        请先链接钱包，然后输入收款地址和数量，点击发送即可。
        使用前请确保你的钱包有足够的TON或Jeton代币
        <Spacer />
        本工具暂不支持NFT转账
        <Spacer />
        全网最低费用0.1TON
        <Spacer />
      </Typography>
    </StyledDescription>
  );
}
