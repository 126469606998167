import { isValidAddress } from "utils";
import BN from "bn.js";
import BigNumberDisplay from "components/BigNumberDisplay";
export const validateTransfer = (
  toAddress?: string,
  amount?: BN,
  balance?: BN,
  symbol?: string,
  decimals?: string,
): string | undefined | JSX.Element => {
  if (!toAddress) {
    return "请输入地址";
  }

  if (toAddress && !isValidAddress(toAddress)) {
    return "地址输入错误";
  }

  if (!amount) {
    return "请输入数量";
  }

  if (amount.gt(balance!!)) {
    return (
      <>
        最大可转数量为{" "} <BigNumberDisplay value={balance!!} decimals={decimals} />{" "}
        {symbol}
      </>
    );
  }
};
